import axios from "axios";
import router from "@/router";
//export const baseURL = 'http://localhost:8081';

// let baseURL = '';
// if (process.env.NODE_ENV == 'production') { 
//     //baseURL = `${process.env.VUE_APP_HTTP}://${process.env.VUE_APP_HOSTNAME}:8081`;
//     baseURL = `${process.env.VUE_APP_HTTP}://${process.env.VUE_APP_HOSTNAME}:8081`;
// } else if (process.env.NODE_ENV == 'testing') {
//     baseURL = `${process.env.VUE_APP_HTTP}://${process.env.VUE_APP_HOSTNAME}:8082`;
// }
//let baseURL = `${process.env.VUE_APP_HTTP}://${process.env.VUE_APP_HOSTNAME}:${process.env.VUE_APP_PORT}`;
//if (process.env.VUE_APP_PORT) {
//baseURL += `:${process.env.VUE_APP_PORT}`;
//}


//let API_URL = `${process.env.VUE_APP_APIURL}`;
let API_URL = '';
console.log(window.location);
if (window.location.hostname == 'mizune.com.br') {
    if (window.location.protocol == 'https:') {
        API_URL = 'https://api.mizune.com.br';
    } else {
        if (window.location.port == '86') {        
            API_URL = 'http://mizune.com.br:8082';
        } else {
            API_URL = 'http://mizune.com.br:8081';
        }
    }
} else if (window.location.hostname == 'teste.mizune.com.br') {
    API_URL = 'https://apiteste.mizune.com.br';
} else {
    API_URL = 'http://localhost:8081';
}
// if (window.location.hostname == 'teste.mizune.com.br') {
//     API_URL = 'https://mizune.com.br:8082';
// } else if (window.location.hostname == 'mizune.com.br' ) {
//     API_URL = 'https://api.mizune.com.br';
// } else {
//     API_URL = 'http://localhost:8081';
// }
export { API_URL };

//export const baseURL = `http://${process.env.VUE_APP_HOSTNAME}:${process.env.VUE_APP_PORT}`;
console.log(process.env);
console.log('API_URL', API_URL);
console.log(window.location.hostname);

//console.log(router);

function authHeader() {
    const token = JSON.parse(localStorage.getItem('mizunetoken'));  
    if (token) {
      return { Authorization: 'Bearer ' + token };
    } else {
      return {};
    }
  }

  const api = axios.create({
    baseURL: API_URL
  });

api.interceptors.request.use(
    (config) => {
        //console.log('config', config);
        config.headers = {
            ...config.headers,
            ...authHeader()
        };
        return config;
    },
    (error) => {        
        return Promise.reject(error);
        
    }
);

api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
        console.log('ERROR INTERCEPTOR', error);
        if (router && error.code == "ERR_NETWORK") {
            console.log('Redirecting to error page');
            router.push({ name: 'errorPage', state: { error: "Erro de Conexão", message: "Não foi possível estabelecer uma conexão com a API.", path: "" } });
        }
        const errorMessage = error.response?.data?.message;
        if (router && errorMessage && errorMessage.includes("Unable to acquire JDBC Connection [Connection to")) {
            console.log('Condition met: JDBC Connection');
            console.log('Redirecting to error page');
            router.push({ name: 'errorPage', state: { error: "Erro de Conexão com o banco de dados", message: "Ocorreu um erro na API que não conseguiu se conectar a base de dados.", path: "" } });
        }
        if (router && errorMessage === "Token JWT inválido ou expirado!") {
            console.log('Condition met: JWT invalid or expired');
            console.log('Redirecting to logout');
            router.push('/logout');
        }
        //} else if (error.response) {
            //console.log('Response Error', error.response);
            //router.push({ name: 'errorPage', state: { error: error.response.data.error, message: error.response.data.message, path: error.response.data.path } });
        //} else if (error.request) {            
            //console.log('Request Error', error.request);
        //} else {
            //console.log('Outro erro', error.message);
        //}
        //console.log('Promise.reject');
        return Promise.reject(error);
    }
);

export default api