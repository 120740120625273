// import { custom, alert } from 'devextreme/ui/dialog';
// import notify from 'devextreme/ui/notify';
//import { useConfirm } from "primevue/useconfirm";

import moment from 'moment';
import { nextTick } from 'vue';
import store from '@/store';
const momentDateFormat = 'DD/MM/YYYY';
// eslint-disable-next-line no-unused-vars
const momentDateTimeFormat = 'DD/MM/YYYY HH:mm:ss';
export const defaultPageSize = 20;

//const confirm = useConfirm();

export const isEmpty = (value) => value == undefined || value == null || value == '';
export const isNotEmpty = (value) => value != undefined && value != null && value != '';

export const dlgBreakPointsDefault = () => ({ '6000px': '60vw', '1199px': '75vw', '575px': '90vw' });
export const dlgBreakPointsLarge = () => ({ '6000px': '80vw', '1199px': '90vw', '575px': '95vw' });

export const clonarObjetoJson = (obj) => {
    for (const key in obj) {
        if (obj[key] instanceof Date) {
            if (isOnlyDate(obj[key])) {
                obj[key] = dateToSqlDate(obj[key]);
            } else {
                obj[key] = dateTimeToString(obj[key]);
            }
        }
    }
    return JSON.parse(JSON.stringify(obj));
}

function isOnlyDate(date) {
    return date.getHours() === 0 &&
           date.getMinutes() === 0 &&
           date.getSeconds() === 0 &&
           date.getMilliseconds() === 0;
}

export const extractDatefromDateTimeString = (dateTimeString) => {
    return dateTimeString.split(' ')[0];
}

export const extractTime = (dateTimeString) => {
    if (dateTimeString == null) return null;
    if ((typeof dateTimeString == 'string') && isNotEmpty(dateTimeString))  {
        if (dateTimeString.length === 8) {
            // Verifica se está no formato nn:nn:nn
            const timePattern = /^\d{2}:\d{2}:\d{2}$/;
            if (timePattern.test(dateTimeString)) {
                // Retorna o valor nn:nn
                return dateTimeString.slice(0, 5);
            }
        } else if (dateTimeString.length === 5) {
            // Retorna a string inteira se tiver 5 caracteres
            return dateTimeString;
        } else {
            
            // eslint-disable-next-line no-unused-vars
            const [date, time] = dateTimeString.split(' ');
            if (time) {
                //console.log(date);
                //console.log(time);
                if (time.length === 8) {
                    // Se time tem 8 caracteres, retorna nn:nn
                    return time.slice(0, 5);
                } else if (time.length === 5) {
                    // Se time tem 5 caracteres, retorna time diretamente
                    return time;
                }
            }
        }
    } else {
        const momentDate = moment(dateTimeString);
        const formattedTime = momentDate.format('HH:mm').toDate();
        return formattedTime;
    }
    return null;
}

export const calcularIdade = (dataNascimento) => {
    if (!dataNascimento) {
        return "";
    }
    const [dia, mes, ano] = dataNascimento.split('/').map(Number);
    const dataNasc = new Date(ano, mes - 1, dia);
    const hoje = new Date();

    let idadeAnos = hoje.getFullYear() - dataNasc.getFullYear();
    let idadeMeses = hoje.getMonth() - dataNasc.getMonth();

    if (idadeMeses < 0) {
        idadeAnos--;
        idadeMeses += 12;
    } else if (idadeMeses === 0 && hoje.getDate() < dataNasc.getDate()) {
        idadeAnos--;
        idadeMeses = 11;
    }
    return `${idadeAnos} anos e ${idadeMeses} meses`;
}

// const hasTime = (datetimeString) => {
//     console.log(datetimeString.toString());
//     const parts = datetimeString.toString().split(' ');
//     const timePart = parts[4];
//     return timePart !== "00:00:00";
// }

export const dateToPayload = (payload, fieldname) => {
    if (payload[fieldname]) {
        payload[fieldname] = dateToSqlDate(payload[fieldname]);
    }
}

export const sqlDateToDate = (dataEmString) => {
    let dateFormated = dataEmString ? moment(dataEmString, momentDateFormat).toDate() : "";
    return dateFormated;
}

export const sqlDateTimeToDate = (dataEmString) => {
    let dateFormated = dataEmString ? moment(dataEmString, momentDateTimeFormat).toDate() : "";
    return dateFormated;
}

export const dateToSqlDate = (date) => {
    const parsedDate = moment(date, moment.ISO_8601, true);
    if (parsedDate.isValid()) {
        return parsedDate.format(momentDateFormat);
    } else {
        return moment(date, momentDateFormat).format(momentDateFormat);
    }
}

export const dateTimeToString = (date) => {
    const parsedDate = moment(date, moment.ISO_8601, true);
    if (parsedDate.isValid()) {
        return parsedDate.format(momentDateTimeFormat);
    } else {
        return moment(date, momentDateFormat).format(momentDateTimeFormat);
    }
}

export const parseJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export const getDataAtual = (format) => {
    if (format) {
        return moment().format(format);
    } else {
        return moment().format(momentDateFormat);
    }
}

export const getDataAtualAddDias = (qdeDias) => {
    // const dataAtual = moment();
    // const dataAtualMais30Dias = dataAtual.add(30, 'days');
    // return dataAtualMais30Dias.format(momentDateFormat);
    return moment().add(qdeDias, 'days').format(momentDateFormat);
}

export const formatCurrency = (value) => {
    return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(value)
}

export const possuiCaracteresEspeciais = (str) => {
    // Utiliza uma expressão regular para verificar se há caracteres não numéricos
    return /[^a-zA-Z0-9]/.test(str);
}

export const isNumericOnly = (value) => {
    return /^\d+$/.test(value);
}

export const formatNumber = (value) => {
    //const roundedValue = Math.round(value * 100) / 100;
    // Converte o número para uma string usando ponto como separador decimal
    const stringValue = value.toFixed(2);
    //console.log(stringValue);
    return stringValue.replace('.', ',');
    // Substitui o ponto por vírgula na string e converte de volta para número
    //const formattedValue = parseFloat(stringValue.replace('.', ','));
    //return formattedValue;
}

export const getMesAtual = () => {
    const mesAtual = moment().month() + 1;
    return mesAtual;
}

export const getPrimeiroDiaDoMes = (mes) => {    
    if (!mes) {
        mes = getMesAtual();
    }
    const anoAtual = moment().year();
    const primeiroDia = moment(`${anoAtual}-${mes}-01`).format(momentDateFormat);
    return primeiroDia;
}

export const getUltimoDiaDoMes = (mes) => {
    if (!mes) {
        mes = getMesAtual();
    }
    const anoAtual = moment().year();
    const primeiroDia = moment(`${anoAtual}-${mes}-01`);
    let ultimoDia = moment(primeiroDia).endOf('month').format(momentDateFormat);
    return ultimoDia;
}

export const usuarioTemPrivilegio = (token, role) => {
    //const usuario = JSON.parse(localStorage.getItem('usuario'));
    //const token = usuario.token;
    if ((role == null) || (role.length == 0)) {
        return true;
    }
    const parsetoken = parseJwt(token);
    const stringRoles = parsetoken.roles;
    const cleanString = stringRoles.replace(/\[|\]|\s/g, '');
    const arrayRoles = cleanString.split(',');
    //console.log('arrayRoles', arrayRoles);
    //console.log('role', role);
    return arrayRoles.some((mineroles) => role.includes(mineroles));   
}

/**
 * Verifica se código informado que é passado por parâmetro na URL é numérico. Caso não seja, o único valor aceito é "novo"
 * @param {string} codigo - Código a ser checado.
 * @return {boolean} Retorna um boleano true caso o valor seja numerico ou igual a "novo"
 */
export const checkCodigoEntrada = (codigo) => {
    return !isNaN(codigo) || codigo === 'novo';
}

export const isNovoRegistro = (codigo) => {
    return (isNaN(codigo) && (codigo == 'novo'))
}

export const setFocus = (nomeElemento) => {
    nextTick(() => {
        const element = document.getElementById(nomeElemento);
        if (element) {
            if (element.tagName.toLowerCase() != 'input') {
                const inputelement = element.querySelector('input');
                if (inputelement) {
                    inputelement.focus();
                    inputelement.select();
                }
            } else {
                element.focus();
                element.select();
            }
        }
    });
}

export const getSistemaSelecionado = () => {
    return JSON.parse(localStorage.getItem('sistemaselecionado'));
}

export const setSistemaSelecionado = (sistema) => {
    localStorage.setItem('sistemaselecionado', sistema);
}

export const getUsuario = () => {
    return store.state.usuario;
}

export const getUsuarioIsMaster = () => {
    return store.state.usuario.authorities.some(a => a.authority == 'MASTER');
}

export const getUsuarioHasAuthority = (authority) => {
    return store.state.usuario.authorities.some(a => a.authority == authority);
}

export const getCdempresa = () => {
    return store.state.usuario?.cdempresa??null;
}



// export const getConfirmacao = () => {
//     console.log(this);
//     confirm.require({
//         message: 'Are you sure you want to proceed?',
//         header: 'Confirmation',
//         icon: 'pi pi-exclamation-triangle',
//         accept: () => {
//             console.log('accept');
//             //toast.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
//         },
//         reject: () => {
//             console.log('reject');
//             //toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
//         }
//     });
// }

// export const getConfirmacao = async (vMensagem) => {
//     let myDialog = custom({
//         title: "Confirmação",
//         messageHtml: vMensagem,
//         buttons: [{ text: "Sim", onClick: () => { return true } },
//                   { text: "Não", onClick: () => { return false } }, 
//         ]
//     });
//     return await myDialog.show();
// }

// export const getMensagemInformacao = (vMensagem) => {
//     alert(vMensagem, "Informação")
// }

// export const getNotify = (vMensagem) => {
//     //notify(vMensagem, { position: "top", direction: "up-push" });
//     notify({ message: vMensagem, width: 300, shading: true }, { position: "center", direction: "up-push" });
// }

// export const frmSetFocus = (formulario, dataFieldName) => {
//     formulario.value.instance.getEditor(dataFieldName).focus();
// }

// export const frmUpdate = (formulario, dataSet) => {
//     formulario.value.instance.updateData(dataSet);
// }
