<template>

    <div v-if="isLoading" class="mt-3">

        <div v-for="n in 20" :key="n">
            <Skeleton class="mb-2" />
        </div>
    </div>


    <ul class="layout-menu" v-if="!isLoading">
        <template v-for="(item, i) in model" :key="item">
            <app-menu-item v-if="!item.separator" :item="item" :index="i"></app-menu-item>
            <li v-if="item.separator" class="menu-separator"></li>
        </template>
    </ul>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import AppMenuItem from './AppMenuItem.vue';
import { getMenus } from '@/services/menus.service';
import { usuarioTemPrivilegio, getSistemaSelecionado } from '@/util';
import store from '@/store'; 
const isLoading = ref(true);

const transformArray = (originalArray, parentId = null) => {
    const result = [];
    originalArray.forEach(item => {
        if (item.cdmenupai === parentId) {
            const newItem = { ...item };
            const children = transformArray(originalArray, item.cdmenu);
            if (children.length) {
                newItem.children = children;
            }
            if (usuarioTemPrivilegio(item.permissoes.map(permissao => permissao.nmpermissao))) {
                result.push(newItem);
            }
        }

    });
    return result;
};


onMounted(async () => {
    loadMenus();
});

const model = ref([]);
const loadMenus = async () => {
    isLoading.value = true;
    const cdsistema = getSistemaSelecionado();
    const retornoMenus = await getMenus(cdsistema);
    const userAuthorities = store.state.usuario.authorities.map(auth => auth.authority);
    const menusFiltrados = [];
    retornoMenus.forEach((item) => {
        const hasPermission = item.permissoes.some(permissao => userAuthorities.includes(permissao.nmpermissao));
        if (hasPermission) {
            menusFiltrados.push(item);
        }
    });
    model.value = transformArray(menusFiltrados);
    isLoading.value = false;
}

defineExpose({
    loadMenus
});

// eslint-disable-next-line no-unused-vars
// const model = ref([
//     {
//         label: 'Home',
//         items: [{ label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/' }]
//     },
//     {
//         label: 'Cadastros',
//         items: [
//         { label: 'Pessoas', icon: 'pi pi-fw pi-id-card', to: '/pessoas' },
//         { label: 'Municípios', icon: 'pi pi-fw pi-id-card', to: '/municipios' },
//         { label: 'Bairros', icon: 'pi pi-fw pi-id-card', to: '/bairros' },
//         { label: 'Unidades de Medida', icon: 'pi pi-fw pi-id-card', to: '/fin/unidadesmedida' },
//         ]
//     },
//     {
//         label: 'Financeiro',
//         items: [
//         { label: 'Categorias de Contas a Pagar', icon: 'pi pi-fw pi-id-card', to: '/fin/contaspagarcategoria' },
//         { label: 'Contas a Pagar', icon: 'pi pi-fw pi-id-card', to: '/fin/contaspagar' },
//         { label: 'Categorias de Contas a Receber', icon: 'pi pi-fw pi-id-card', to: '/fin/contasrecebercategoria' },
//         { label: 'Contas a Receber', icon: 'pi pi-fw pi-id-card', to: '/fin/contasreceber' },
//         { label: 'Produtos', icon: 'pi pi-fw pi-id-card', to: '/fin/produtos' },
//         { label: 'Vendas', icon: 'pi pi-fw pi-id-card', to: '/fin/vendas' },
//         { label: 'NF-e', icon: 'pi pi-fw pi-id-card', to: '/fin/nfe' },
//         ]
//     },   

//     {
//         label: 'Administrativo',
//         items: [
//         { label: 'Empresas', icon: 'pi pi-fw pi-id-card', to: '/empresas' },
//         { label: 'Usuários', icon: 'pi pi-fw pi-id-card', to: '/pdr/usuarios' },
//         { label: 'Permissões', icon: 'pi pi-fw pi-id-card', to: '/permissoes' },
//         { label: 'Parâmetros', icon: 'pi pi-fw pi-id-card', to: '/parametros' },
//         { label: 'Menus', icon: 'pi pi-fw pi-id-card', to: '/pdr/menus' },
//         ]
//     },
//     // {
//     //     label: 'UI Components',
//     //     items: [
//     //         { label: 'Form Layout', icon: 'pi pi-fw pi-id-card', to: '/uikit/formlayout' },
//     //         { label: 'Input', icon: 'pi pi-fw pi-check-square', to: '/uikit/input' },
//     //         { label: 'Float Label', icon: 'pi pi-fw pi-bookmark', to: '/uikit/floatlabel' },
//     //         { label: 'Invalid State', icon: 'pi pi-fw pi-exclamation-circle', to: '/uikit/invalidstate' },
//     //         { label: 'Button', icon: 'pi pi-fw pi-mobile', to: '/uikit/button', class: 'rotated-icon' },
//     //         { label: 'Table', icon: 'pi pi-fw pi-table', to: '/uikit/table' },
//     //         { label: 'List', icon: 'pi pi-fw pi-list', to: '/uikit/list' },
//     //         { label: 'Tree', icon: 'pi pi-fw pi-share-alt', to: '/uikit/tree' },
//     //         { label: 'Panel', icon: 'pi pi-fw pi-tablet', to: '/uikit/panel' },
//     //         { label: 'Overlay', icon: 'pi pi-fw pi-clone', to: '/uikit/overlay' },
//     //         { label: 'Media', icon: 'pi pi-fw pi-image', to: '/uikit/media' },
//     //         { label: 'Menu', icon: 'pi pi-fw pi-bars', to: '/uikit/menu', preventExact: true },
//     //         { label: 'Message', icon: 'pi pi-fw pi-comment', to: '/uikit/message' },
//     //         { label: 'File', icon: 'pi pi-fw pi-file', to: '/uikit/file' },
//     //         { label: 'Chart', icon: 'pi pi-fw pi-chart-bar', to: '/uikit/charts' },
//     //         { label: 'Misc', icon: 'pi pi-fw pi-circle', to: '/uikit/misc' }
//     //     ]
//     // },
//     // {
//     //     label: 'Prime Blocks',
//     //     items: [
//     //         { label: 'Free Blocks', icon: 'pi pi-fw pi-eye', to: '/blocks', badge: 'NEW' },
//     //         { label: 'All Blocks', icon: 'pi pi-fw pi-globe', url: 'https://www.primefaces.org/primeblocks-vue', target: '_blank' }
//     //     ]
//     // },
//     // {
//     //     label: 'Utilities',
//     //     items: [
//     //         { label: 'PrimeIcons', icon: 'pi pi-fw pi-prime', to: '/utilities/icons' },
//     //         { label: 'PrimeFlex', icon: 'pi pi-fw pi-desktop', url: 'https://www.primefaces.org/primeflex/', target: '_blank' }
//     //     ]
//     // },
//     // {
//     //     label: 'Pages',
//     //     icon: 'pi pi-fw pi-briefcase',
//     //     to: '/pages',
//     //     items: [
//     //         {
//     //             label: 'Landing',
//     //             icon: 'pi pi-fw pi-globe',
//     //             to: '/landing'
//     //         },
//     //         {
//     //             label: 'Auth',
//     //             icon: 'pi pi-fw pi-user',
//     //             items: [
//     //                 {
//     //                     label: 'Login',
//     //                     icon: 'pi pi-fw pi-sign-in',
//     //                     to: '/auth/login'
//     //                 },
//     //                 {
//     //                     label: 'Error',
//     //                     icon: 'pi pi-fw pi-times-circle',
//     //                     to: '/auth/error'
//     //                 },
//     //                 {
//     //                     label: 'Access Denied',
//     //                     icon: 'pi pi-fw pi-lock',
//     //                     to: '/auth/access'
//     //                 }
//     //             ]
//     //         },
//     //         {
//     //             label: 'Crud',
//     //             icon: 'pi pi-fw pi-pencil',
//     //             to: '/pages/crud'
//     //         },
//     //         {
//     //             label: 'Timeline',
//     //             icon: 'pi pi-fw pi-calendar',
//     //             to: '/pages/timeline'
//     //         },
//     //         {
//     //             label: 'Not Found',
//     //             icon: 'pi pi-fw pi-exclamation-circle',
//     //             to: '/pages/notfound'
//     //         },
//     //         {
//     //             label: 'Empty',
//     //             icon: 'pi pi-fw pi-circle-off',
//     //             to: '/pages/empty'
//     //         }
//     //     ]
//     // },
//     // {
//     //     label: 'Hierarchy',
//     //     items: [
//     //         {
//     //             label: 'Submenu 1',
//     //             icon: 'pi pi-fw pi-bookmark',
//     //             items: [
//     //                 {
//     //                     label: 'Submenu 1.1',
//     //                     icon: 'pi pi-fw pi-bookmark',
//     //                     items: [
//     //                         { label: 'Submenu 1.1.1', icon: 'pi pi-fw pi-bookmark' },
//     //                         { label: 'Submenu 1.1.2', icon: 'pi pi-fw pi-bookmark' },
//     //                         { label: 'Submenu 1.1.3', icon: 'pi pi-fw pi-bookmark' }
//     //                     ]
//     //                 },
//     //                 {
//     //                     label: 'Submenu 1.2',
//     //                     icon: 'pi pi-fw pi-bookmark',
//     //                     items: [{ label: 'Submenu 1.2.1', icon: 'pi pi-fw pi-bookmark' }]
//     //                 }
//     //             ]
//     //         },
//     //         {
//     //             label: 'Submenu 2',
//     //             icon: 'pi pi-fw pi-bookmark',
//     //             items: [
//     //                 {
//     //                     label: 'Submenu 2.1',
//     //                     icon: 'pi pi-fw pi-bookmark',
//     //                     items: [
//     //                         { label: 'Submenu 2.1.1', icon: 'pi pi-fw pi-bookmark' },
//     //                         { label: 'Submenu 2.1.2', icon: 'pi pi-fw pi-bookmark' }
//     //                     ]
//     //                 },
//     //                 {
//     //                     label: 'Submenu 2.2',
//     //                     icon: 'pi pi-fw pi-bookmark',
//     //                     items: [{ label: 'Submenu 2.2.1', icon: 'pi pi-fw pi-bookmark' }]
//     //                 }
//     //             ]
//     //         }
//     //     ]
//     // },
//     // {
//     //     label: 'Get Started',
//     //     items: [
//     //         {
//     //             label: 'Documentation',
//     //             icon: 'pi pi-fw pi-question',
//     //             to: '/documentation'
//     //         },
//     //         {
//     //             label: 'View Source',
//     //             icon: 'pi pi-fw pi-search',
//     //             url: 'https://github.com/primefaces/sakai-vue',
//     //             target: '_blank'
//     //         },
//     //         {
//     //             label: 'Nuxt Version',
//     //             url: 'https://github.com/primefaces/sakai-nuxt',
//     //             icon: 'pi pi-fw pi-star'
//     //         }
//     //     ]
//     //}
// ]);

</script>

<style lang="scss" scoped></style>
