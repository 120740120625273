/* eslint-disable vue/multi-word-component-names */
import { createApp } from 'vue';
import App from './App.vue';
import store from '@/store';
import router from '@/router';
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
import { locale } from '@/assets/locale-pt-br'
import Aura from '@primevue/themes/aura';
//import Lara from '@primevue/themes/lara';
//import Nora from '@primevue/themes/nora';
import '@/assets/styles.scss';
//import 'devextreme/dist/css/dx.light.css';

import InputNumber from 'primevue/inputnumber';
import InputText from "primevue/inputtext";
import AutoComplete from 'primevue/autocomplete';
import Checkbox from 'primevue/checkbox';
import Toolbar from 'primevue/toolbar';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import DatePicker from 'primevue/datepicker';
import Button from 'primevue/button';
import SelectButton from 'primevue/selectbutton';
import Password from 'primevue/password';
import ConfirmDialog from 'primevue/confirmdialog';
import Dialog from 'primevue/dialog';
import Toast from 'primevue/toast';
import RadioButton from 'primevue/radiobutton';
import Menu from 'primevue/menu';
import TieredMenu from 'primevue/tieredmenu';
import Avatar from 'primevue/avatar';
import Dropdown from 'primevue/dropdown';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import InputMask from 'primevue/inputmask';
import Tree from 'primevue/tree';
import FileUpload from 'primevue/fileupload';
import Textarea from 'primevue/textarea';
import Divider from 'primevue/divider';
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';
import Tooltip from 'primevue/tooltip';
import Fieldset from 'primevue/fieldset';
import ScrollPanel from 'primevue/scrollpanel';
import Message from 'primevue/message';
import ProgressBar from 'primevue/progressbar';
import FloatLabel from 'primevue/floatlabel';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import Panel from 'primevue/panel';
import Skeleton from 'primevue/skeleton';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import DataView from 'primevue/dataview';
import Badge from 'primevue/badge';
import Tag from 'primevue/tag';
import SplitButton from 'primevue/splitbutton';

const app = createApp(App);

app.component('InputNumber', InputNumber);
app.component('InputText', InputText);
app.component('AutoComplete', AutoComplete);
app.component('Checkbox', Checkbox);
app.component('Toolbar', Toolbar);
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('DatePicker', DatePicker);
app.component('Button', Button);
app.component('SelectButton', SelectButton);
app.component('Password', Password);
app.component('ConfirmDialog', ConfirmDialog);
app.component('Toast', Toast);
app.component('Dialog', Dialog);
app.component('RadioButton', RadioButton);
app.component('Menu', Menu);
app.component('TieredMenu', TieredMenu);
app.component('Avatar', Avatar);
app.component('Dropdown', Dropdown);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('InputMask', InputMask);
app.component('Tree', Tree);
app.component('FileUpload', FileUpload);
app.component('Textarea', Textarea);
app.component('Divider', Divider);
app.component('InputGroup', InputGroup);
app.component('InputGroupAddon', InputGroupAddon);
app.component('Fieldset', Fieldset);
app.component('ScrollPanel', ScrollPanel);
app.component('Message', Message);
app.component('ProgressBar', ProgressBar);
app.component('FloatLabel', FloatLabel);
app.component('IconField', IconField);
app.component('InputIcon', InputIcon);
app.component('Panel', Panel);
app.component('Skeleton', Skeleton);
app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('DataView', DataView);
app.component('Badge', Badge);
app.component('Tag', Tag);
app.component('SplitButton', SplitButton);
app.directive('tooltip', Tooltip);

app.use(router)
app.use(PrimeVue, { locale, theme: { preset: Aura }});

app.use(ConfirmationService)
app.use(ToastService)
app.use(store)
app.mount('#app');